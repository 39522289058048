import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-5 g-xxl-8" }
const _hoisted_2 = { class: "col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTWidget6 = _resolveComponent("KTWidget6")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTWidget6, {
        user: _ctx.user,
        "widget-classes": "mb-5 mb-xxl-8"
      }, null, 8, ["user"])
    ])
  ]))
}