
import { defineComponent, onMounted } from "vue";

import KTWidget6 from "@/components/widgets/feeds/Widget6.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";


export default defineComponent({
  name: "overview",
  components: {
    KTWidget6,
  },

  computed: {
    user() {
      return store.getters.getUserInfoAll;
    }
  },


  setup() {

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Pages", "Profile"]);
    });


  },
});
