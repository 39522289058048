
  import {defineComponent, reactive, ref, toRefs} from "vue";
  import {ErrorMessage, Field, Form} from "vee-validate";
  import {useStore} from "vuex";
  import {Actions, Mutations} from "@/store/enums/StoreEnums";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import * as Yup from "yup";
  import router from "@/router/router";
  import {useI18n} from "vue-i18n";
export default defineComponent({

  name: "widget-6",

  props: {
    widgetClasses: String,
    user:Object
  },
  components: {
    ErrorMessage,
    Form,
    Field
  },


  data() {
    return {
      userObject: this.user,

    };
  },


  setup: function () {

    const { t } = useI18n();

    const store = useStore()

    const isUserAuthenticated = store.getters.isUserAuthenticated
      if(isUserAuthenticated == false) {
        router.push({ name: "dashboard" });
      }


    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      first_name: Yup.string().required(`${t('is_required')}`).label(`${t('first_name')}`),
      last_name: Yup.string().required(`${t('is_required')}`).label(`${t('last_name')}`),
      email: Yup.string().min(4).required(`${t('is_required')}`).email().label(`${t('email')}`),
      phone: Yup.string().min(8).required(`${t('is_required')}`).label(`${t('phone')}`),
      address: Yup.string().min(8).required(`${t('is_required')}`).nullable().label(`${t('address')}`),
      // password: Yup.string().min(4).required().label("Password"),
      // password_confirmation: Yup.string()
      //     .min(4)
      //     .required()
      //     .oneOf([Yup.ref("password"), null], "Passwords must match")
      //     .label("Password Confirmation"),
    });


    const edit = (values) => {
      console.log(1);
      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const formData = new FormData
      if (values.avatar) {
        let file = values.avatar[0]
        formData.append('avatar', file)
      }
      formData.append('first_name', values.first_name)
      formData.append('last_name', values.last_name)
      formData.append('phone', values.phone)
      formData.append('email', values.email)
      formData.append('address', values.address)
      formData.append('new_password', values.new_password)
      formData.append('current_password', values.current_password)
      formData.append('confirm_password', values.confirm_password)

      setTimeout(() => {
        // Send login request

        store.dispatch(Actions.EDIT_USER, formData)
            .then((response) => {
              store.dispatch(Actions.GET_USER)
              store.getters.getUserInfoAll
              Swal.fire({
                text: `${t('updated')}`,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              })
            })
            .catch(() => {
              const error = Object.values(store.getters.getErrors.error);

              Swal.fire({
                html: error.map(item => `<p>${item}</p>`)
                    .join(''),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 1000);

    }

    return {
      edit,
      registration,
      submitButton
    }
  },
  methods:{
    setUser(user){
      this.userObject = user;
    },
  },

});
